import { ValueGetterParams } from '@ag-grid-community/core';
import {
  EntityListField,
  PERMISSION_ACCESS,
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@do/app-common';
import { Customer } from '@do/app-domain-customer';
import { FieldType, FilterType } from '@do/common-interfaces';
import { marker } from '@do/ngx-translate-extract-marker';

import { MasterControlUnit } from './models';

export const ENTITY = marker('master_control_unit');

export const PERMISSION_ACCESS_ENTITY = `${PERMISSION_ACCESS}_${ENTITY}`;
export const PERMISSION_CREATE_ENTITY = `${PERMISSION_CREATE}_${ENTITY}`;
export const PERMISSION_READ_ENTITY = `${PERMISSION_READ}_${ENTITY}`;
export const PERMISSION_UPDATE_ENTITY = `${PERMISSION_UPDATE}_${ENTITY}`;
export const PERMISSION_DELETE_ENTITY = `${PERMISSION_DELETE}_${ENTITY}`;

export const getGridColumn = (customers: Customer[]) => {
  const gridColumns: EntityListField[] = [
    {
      elementLabel: marker('Cinema'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinemaRoom.cinema.name',
      // sort: 'asc',
    },
    {
      elementLabel: marker('Hall'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinemaRoom.name',
      // sort: 'asc',
    },
    {
      elementLabel: marker('Mac Address'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'macAddress',
      sortable: true,
    },
    {
      elementLabel: marker('Firmware version'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'firmwareVersion',
      sortable: true,
    },
    {
      elementLabel: marker('Online'),
      filterType: FilterType.boolean,
      fieldType: FieldType.boolean,
      field: 'offline',
      sortable: true,
      valueGetter: (params: ValueGetterParams<MasterControlUnit>) => {
        return !params.data?.offline;
      },
      maxWidth: 100,
    },
    {
      elementLabel: marker('Provisioned'),
      filterType: FilterType.boolean,
      fieldType: FieldType.boolean,
      field: 'provisioned',
      sortable: true,
      maxWidth: 120,
    },
    {
      elementLabel: marker('Active'),
      filterType: FilterType.boolean,
      fieldType: FieldType.boolean,
      field: 'active',
      sortable: true,
    },
    {
      elementLabel: marker('Connected clients'),
      filterType: FilterType.boolean,
      fieldType: FieldType.boolean,
      field: 'cinemaRoom.cinema.hasClientsConnected',
      sortable: true,
      maxWidth: 130,
    },
    {
      elementLabel: marker('Chain'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinemaRoom.cinema.cinemaChain.name',
      // sort: 'asc',
    },
    {
      elementLabel: marker('Customer'),
      filterType: FilterType.text,
      fieldType: FieldType.text,
      field: 'cinemaRoom.cinema.cinemaChain.customerId',
      // sort: 'asc',
      valueGetter: (params: ValueGetterParams<MasterControlUnit>) => {
        const customer = customers.find(
          (c) =>
            c.id === params.data?.cinemaRoom?.cinema?.cinemaChain?.customerId
        );
        if (customer) {
          return customer.name;
        }

        return '';
      },
    },
  ];
  return gridColumns;
};
